import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../CartPage.css'; // Import the specific CSS file
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css'; // Import Zoom CSS
import APIURL from '../config'; // Import the global API URL
import UserAddressForm from './UserAddressForm'; // Import UserAddressForm

function CartPage() {
  const [cartItems, setCartItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [orderSuccess, setOrderSuccess] = useState(false);
  const [orderError, setOrderError] = useState('');
  const [showAddressForm, setShowAddressForm] = useState(false); // State to manage address form visibility
  const [addressUpdated, setAddressUpdated] = useState(false); // State to manage address update status
  const navigate = useNavigate();

  const userId = localStorage.getItem('userId');

  useEffect(() => {
    const fetchCart = async () => {
      if (!userId) {
        navigate('/login');
        return;
      }

      try {
        const response = await axios.get(`${APIURL}/cart/${userId}`,{headers: {'Authorization': 'ubpatil'}});
        setCartItems(response.data);
      } catch (error) {
        console.error('Error fetching cart:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchCart();
  }, [userId, navigate]);

   
  const handleClose = () => {
    navigate('/');
  };

  const handleRemoveFromCart = async (itemId) => {
    if (!userId) {
      navigate('/login');
      return;
    }

    try {
      await axios.delete(`${APIURL}/cart/${userId}/${itemId}`,{headers: {'Authorization': 'ubpatil'}});
      setCartItems(prevItems => prevItems.filter(item => item.cartid !== itemId));
    } catch (error) {
      console.error('Error removing item from cart:', error);
    }
  };

  const handleAddressUpdate = () => {
    setAddressUpdated(true); // Update the address and show confirmation button
  };

   

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="cart-page-container">
      <h2 className="cart-page-title">Your Cart</h2>
      <button onClick={handleClose} className="cart-btn cart-btn-back">Go Back</button>

      {orderSuccess && (
        <div className="cart-modal">
          <div className="cart-modal-content">
            <span className="cart-modal-close" onClick={() => setOrderSuccess(false)}>&times;</span>
            <h4>Enquiry sent successfully!</h4>
            <p>Your Enquiry has been sent. Thank you for contacting with us.</p>
            <button onClick={handleClose} className="cart-btn cart-btn-primary">Close</button>
          </div>
        </div>
      )}

      {orderError && <div className="alert alert-danger">{orderError}</div>}

      {cartItems.length === 0 ? (
        <div className="cart-empty">
          <img src="https://via.placeholder.com/100" alt="Empty Cart" />
          <p>Your cart is empty.</p>
          <p>Add items to your cart to proceed with the checkout.</p>
        </div>
      ) : (
        <div>
          <div className="row">
            {cartItems.map(item => (
              <div key={item.cartid} className="col-md-4 mb-4">
                <div className="card">
                  <Zoom>
                    <img
                     src={`${APIURL}/Images/${item.product_id}.jpg`}
                      className="cart-card-img-top zoom-image"
                      alt={item.title}
                    />
                  </Zoom>
                  <div className="card-body">
                    <h5 className="card-title">{item.title}</h5>
                    <p className="card-text">Price: INR {item.price}</p>
                    <p className="card-text">Quantity: {item.quantity}</p>
                    <p className="card-text"><strong>Size:</strong> {item.size}</p>
                    <p className="card-text"><strong>Material:</strong> {item.material}</p>
                    <p className="card-text"><strong>Features:</strong> {item.features}</p>
                    <button onClick={() => handleRemoveFromCart(item.cartid)} className="btn btn-danger">Remove</button>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {!showAddressForm ? (
            <div className="cart-place-order">
              <button onClick={() => setShowAddressForm(true)} className="cart-btn cart-btn-order">Send Enquiry</button>
            </div>
          ) : (
            <div className="address-form-container">
              <UserAddressForm onAddressUpdate={handleAddressUpdate} />
              
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default CartPage;
