import React from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import AddProduct from './AddProduct';
import UploadProduct from './UploadProduct'; 
import OrderDetails from './OrderDetails';
import ReportPage from './ReportPage';
import ProductList from './ProductList';
import UserList from './UserList';
import ContactPanel from './ContactPanel';
import AuthWrapper from './AuthWrapper';
import AdminPage from './AdminPage';

function AdminDashboard() {
    return (
        <div className="admin-dashboard">
            <nav>
                <ul>
                    <li><Link to="contact-list">Contacts List</Link></li>
                    <li><Link to="user-list">User List</Link></li>
                    <li><Link to="add-product">Capture Product</Link></li>
                    <li><Link to="upload-product">Upload Product</Link></li>
                    <li><Link to="product-list">Product List</Link></li>
                    <li><Link to="view-orders">View Orders</Link></li>
                    <li><Link to="adminpage">AdminPage</Link></li>
                </ul>
            </nav>
            <div className="admin-content">
                <Routes>
                    <Route path="contact-list" element={
                        <AuthWrapper>
                            <ContactPanel />
                        </AuthWrapper>
                    } />
                    <Route path="user-list" element={
                        <AuthWrapper>
                            <UserList />
                        </AuthWrapper>
                    } />
                    <Route path="add-product" element={
                        <AuthWrapper>
                            <AddProduct />
                        </AuthWrapper>
                    } />
                    <Route path="upload-product" element={
                        <AuthWrapper>
                            <UploadProduct />
                        </AuthWrapper>
                    } />
                    <Route path="product-list" element={
                        <AuthWrapper>
                            <ProductList />
                        </AuthWrapper>
                    } />
                    <Route path="view-orders" element={
                        <AuthWrapper>
                            <OrderDetails />
                        </AuthWrapper>
                    } />
                    <Route path="reports" element={
                        <AuthWrapper>
                            <ReportPage />
                        </AuthWrapper>
                    } />
                     <Route path="adminpage" element={
                        <AuthWrapper>
                            <AdminPage />
                        </AuthWrapper>
                    } />
                </Routes>
            </div>
        </div>
    );
}

export default AdminDashboard;
