import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import API_URL from '../../config.js';
import './CSS/AddProduct.css';

const AddProduct = () => {
  const [title, setTitle] = useState('');
  const [idolName, setIdolName] = useState('');
  const [price, setPrice] = useState('');
  const [size, setSize] = useState('');
  const [material, setMaterial] = useState('');
  const [features, setFeatures] = useState('');
  const [isActive, setIsActive] = useState(true);
  const [image, setImage] = useState(null);
  const [alert, setAlert] = useState(null);
  const navigate = useNavigate();

  const handleAddProduct = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('title', title);
    formData.append('idolName', idolName);
    formData.append('price', price);
    formData.append('size', size);
    formData.append('material', material);
    formData.append('features', features);
    formData.append('isActive', isActive ? 1 : 0); // Convert isActive to 1 or 0
    if (image) {
      formData.append('image', image);
    }

    try {
      const response = await axios.post(`${API_URL}/productInserts`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': 'ubpatil',
        },
      });

      setAlert({ type: 'success', text: 'Product added successfully!' });
      setTitle('');
      setIdolName('');
      setPrice('');
      setSize('');
      setMaterial('');
      setFeatures('');
      setIsActive(true);
      setImage(null);
      // Optionally, navigate to another page
      // navigate('/some-page');
    } catch (error) {
      console.error('Failed to add product:', error.response ? error.response.data : error.message);
      setAlert({ type: 'danger', text: 'Failed to add product. Please try again later.' });
    }
  };

  return (
    <div className="add-product-container">
      <h2>Add Product</h2>
      {alert && (
        <div className={`alert alert-${alert.type}`}>
          {alert.text}
        </div>
      )}
      <form onSubmit={handleAddProduct}>
        <div>
          <label>Title:</label>
          <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} required />
        </div>
        <div>
          <label>Idol Name:</label>
          <input type="text" value={idolName} onChange={(e) => setIdolName(e.target.value)} required />
        </div>
        <div>
          <label>Price:</label>
          <input type="number" value={price} onChange={(e) => setPrice(e.target.value)} required />
        </div>
        <div>
          <label>Size:</label>
          <input type="text" value={size} onChange={(e) => setSize(e.target.value)} required />
        </div>
        <div>
          <label>Material:</label>
          <input type="text" value={material} onChange={(e) => setMaterial(e.target.value)} />
        </div>
        <div>
          <label>Features:</label>
          <input type="text" value={features} onChange={(e) => setFeatures(e.target.value)} />
        </div>
        <div>
          <label>Is Active:</label>
          <input
            type="checkbox"
            checked={isActive}
            onChange={(e) => setIsActive(e.target.checked)}
          />
        </div>
        <div>
          <label>Image:</label>
          <input type="file" onChange={(e) => setImage(e.target.files[0])} />
        </div>
        <button type="submit">Add Product</button>
      </form>
    </div>
  );
};

export default AddProduct;
