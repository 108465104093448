import React, { useState, useEffect } from 'react';
import axios from 'axios';
import APIURL from '../../config'; // Ensure this points to your backend URL
import './CSS/UserList.css';

const UserList = () => {
    const [users, setUsers] = useState([]);
    const [filter, setFilter] = useState('');
    const [sort, setSort] = useState('username_asc');
    const [status, setStatus] = useState(null); // null for all, 1 for active, 0 for inactive

    useEffect(() => {
        fetchUsers();
    }, [filter, sort, status]);

    const fetchUsers = async () => {
        try {
            const response = await axios.get(`${APIURL}/userList`, {
                params: {
                    filter,
                    sort,
                    status
                },
                headers: { 
                    'Authorization': 'ubpatil' 
                }
            });
            setUsers(response.data.users);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    const handleFilterChange = (e) => {
        setFilter(e.target.value);
    };

    const handleSortChange = (e) => {
        setSort(e.target.value);
    };

    const handleStatusChange = (e) => {
        setStatus(e.target.value === 'all' ? null : Number(e.target.value));
    };

    const toggleUserStatus = async (userId, isActive) => {
        try {
            await axios.put(`${APIURL}/toggleUserStatus`, 
                { id: userId, isActive: !isActive },
                { headers: { 'Authorization': 'ubpatil' } }
            );
            fetchUsers(); // Refresh user list
        } catch (error) {
            console.error('Error updating user status:', error);
        }
    };

    return (
        <div className="user-list-container">
            <div className="filters">
                <input
                    type="text"
                    placeholder="Filter by username or email"
                    value={filter}
                    onChange={handleFilterChange}
                />
                <select onChange={handleSortChange} value={sort}>
                    <option value="username_asc">Username Ascending</option>
                    <option value="username_desc">Username Descending</option>
                </select>
                <select onChange={handleStatusChange} value={status === null ? 'all' : status}>
                    <option value="all">All</option>
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                </select>
            </div>
            <div className="user-grid">
                {users.map(user => (
                    <div className="user-card" key={user.id}>
                        <div className="user-details">
                            <h3>{user.username}</h3>
                            <p>Email: {user.email}</p>
                            <p>Mobile: {user.mobile}</p>
                            <p>Address: {user.address}, {user.city}, {user.state}, {user.country}</p>
                            <p>Status: {user.isActive ? 'Active' : 'Inactive'}</p>
                            <button onClick={() => toggleUserStatus(user.id, user.isActive)}>
                                {user.isActive ? 'Deactivate' : 'Activate'}
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default UserList;
