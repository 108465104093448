import React, { useEffect } from 'react';
import '../MobileAppPage.css'; // Ensure this file contains necessary styles

function MobileAppPage() {
  useEffect(() => {
    // Automatically trigger download when component mounts
    window.location.href = 'https://nodeapi.yuvalink.com/api/upload/yuvalink.apk';
  }, []);

  return (
    <div className="mobile-app-section">
      <h3>Install the Yuvalink App</h3>
      <p>To stay updated and check the status of your participation, you can install the Yuvalink mobile app by clicking the link below:</p>
      <a href="https://nodeapi.yuvalink.com/api/upload/yuvalink.apk" target="_blank" rel="noopener noreferrer">
        Download Yuvalink App
      </a>
      <p>Install the app on your mobile device and easily track the participant list and contest updates.</p>
    </div>
  );
}

export default MobileAppPage;
