import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import APIURL from '../config'; // Ensure this points to your backend URL
import '../SignupPage.css'; // Import the CSS file

function Signup() {
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [emailError, setEmailError] = useState('');
  const [mobileError, setMobileError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [generalError, setGeneralError] = useState('');
  const navigate = useNavigate();

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validateMobile = (mobile) => {
    const regex = /^\d{10}$/; // Adjust according to your mobile number format
    return regex.test(mobile);
  };

  const handleSignup = async (e) => {
    e.preventDefault();

    // Reset error messages
    setEmailError('');
    setMobileError('');
    setPasswordError('');
    setGeneralError('');

    // Check if fields are non-empty
    if (!email) {
      setEmailError('Email is required.');
      return;
    }

    if (!mobile) {
      setMobileError('Mobile number is required.');
      return;
    }

    if (!password) {
      setPasswordError('Password is required.');
      return;
    }

    // Validate email and mobile formats
    if (!validateEmail(email)) {
      setEmailError('Invalid email format.');
      return;
    }

    if (!validateMobile(mobile)) {
      setMobileError('Invalid mobile number. It should be 10 digits.');
      return;
    }

    try {
      // Proceed with signup if validation passes
      const signupResponse = await axios.post(`${APIURL}/signup`, {
        email,
        mobile,
        password
      }, {
        headers: { 'Authorization': 'ubpatil' }
      });

      if (signupResponse.status === 400 && signupResponse.data.message === 'Account already exists') {
        setGeneralError('Account already exists. Please login.');
      } else if (signupResponse.status === 400 && signupResponse.data.message === 'Account already exists with different password') {
        setGeneralError('Account already exists with a different password. Please login.');
      } else if (signupResponse.status === 201) {
        // Handle successful signup
        localStorage.setItem('userId', signupResponse.data.userId);
        localStorage.setItem('authToken', signupResponse.data.authToken);
        navigate('/');
      }
    } catch (error) {
      console.error('Signup error:', error.response ? error.response.data : error.message);
      setGeneralError(error.response ? error.response.data.message : 'Signup failed. Please try again.');
    }
  };

  console.log('General Error:', generalError); // Log the general error to debug

  return (
    <div className="signup-container">
      <h2>Signup</h2>
      {message && <p>{message}</p>}
      {generalError && <p className="error">{generalError}</p>}
      {generalError && (
        <div>
          <Link to="/login">
            <button className="login-button">Login</button>
          </Link>  
        </div>
      )}
      <form onSubmit={handleSignup}>
      <p className="warning">
  Please do not enter fake mobile numbers or email IDs. We verify the data, and any fake information will be removed, along with the participant.
</p>
 <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          {emailError && <p className="error">{emailError}</p>}
        </div>
        <div className="form-group">
          <label htmlFor="mobile">Mobile:</label>
          <input
            type="tel"
            id="mobile"
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
            required
          />
          {mobileError && <p className="error">{mobileError}</p>}
        </div>
        <div className="form-group">
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          {passwordError && <p className="error">{passwordError}</p>}
        </div>
        <button type="submit">Signup</button>
      </form>
    </div>
  );
}

export default Signup;
