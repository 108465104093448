import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import likeImg from '../Images/like.svg';
import unlikeImg from '../Images/unlike.svg';
import API_URL from '../config';
import '../ParticipantsPage.css';

function ParticipantsPage() {
  const [participants, setParticipants] = useState([]);
  const [totalParticipants, setTotalParticipants] = useState(0);
  const [userId, setUserId] = useState(localStorage.getItem('userId'));
  const [currentPage, setCurrentPage] = useState(1);
  const [participantsPerPage] = useState(50);
  const [zoomedImage, setZoomedImage] = useState(null);
  const [nameFilter, setNameFilter] = useState(''); // Add name filter state
  const navigate = useNavigate();
  const location = useLocation();

  // Extract the participantId from query parameters
  const queryParams = new URLSearchParams(location.search);
  const participantId = queryParams.get('participantId');

  useEffect(() => {
    const fetchParticipants = () => {
      axios
        .get(`${API_URL}/participants?page=${currentPage}&limit=${participantsPerPage}&participantId=${participantId || ''}&name=${nameFilter}`, {
          headers: {
            'Authorization': 'ubpatil',
          }
        })
        .then((response) => {
          setParticipants(response.data.participants || []);
          setTotalParticipants(response.data.totalParticipants || 0);
        })
        .catch((error) => console.error('Error fetching participants:', error));
    };
  
    fetchParticipants();
  
  }, [currentPage, participantsPerPage, location.search, nameFilter, participantId]); // Add participantId to dependencies

  // Handle filter change
  const handleFilterChange = (e) => {
    setNameFilter(e.target.value);
    setCurrentPage(1); // Reset to the first page when filter changes
  };

  const handleLikeToggle = async (participantId) => {
    if (!userId) {
      navigate('/signup');
      return;
    }
  
    try {
      const response = await axios.post(`${API_URL}/like/${participantId}`, {}, {
        headers: {
          'Authorization': 'ubpatil',
          'user-id': userId,
        }
      });
  
      if (response.data.message === 'Like recorded') {
        setParticipants(prevParticipants =>
          prevParticipants.map(participant =>
            participant.id === participantId
              ? { ...participant, like_count: participant.like_count + 1, liked: true }
              : participant
          )
        );
      } else if (response.data.message === 'Like removed') {
        setParticipants(prevParticipants =>
          prevParticipants.map(participant =>
            participant.id === participantId
              ? { ...participant, like_count: participant.like_count - 1, liked: false }
              : participant
          )
        );
      }
    } catch (error) {
      console.error('Error toggling like:', error);
    }
  };

  const handleImageClick = (image) => {
    setZoomedImage(image);
  };

  const closeZoomedImage = () => {
    setZoomedImage(null);
  };

  const totalPages = Math.ceil(totalParticipants / participantsPerPage);

  const generateWhatsAppLink = (participant) => {
    const message = `Hi! I need your help to support ${participant.name} in a contest. Please like their profile using this link:\n\n${window.location.href}?participantId=${participant.id}\n\nGanpati Bappa Moraya! Your support means a lot and could help them win the top spot! Thank you!\n\nTo stay updated and check the status of your participation, you can install the Yuvalink mobile app by clicking the link below:\n\nDownload Yuvalink App: ${window.location.origin}/mobile-app`;
    
    return `https://wa.me/?text=${encodeURIComponent(message)}`;
  };
  

  return (
    <div className="people-container">
      <h1>Ganpati Bappa Contest 2024</h1>
      <p>This contest is held in celebration of Ganpati Bappa and runs for 11 days. Participants with the most likes will be nominated for awards. Show your support by liking your favorite participants!</p>
      <div className="mobile-app-section">
        <h3>Install the Yuvalink App</h3>
        <p>To stay updated and check the status of your participation, you can install the Yuvalink mobile app by clicking the link below:</p>
        <a href="/mobile-app" target="_blank" rel="noopener noreferrer">
          Download Yuvalink App
        </a>
        <p>Install the app on your mobile device and easily track the participant list and contest updates.</p>
      </div>
      <p className="warning">
       Please do not enter fake mobile numbers or email IDs. We verify the data, and any fake information will be removed, along with the participant.
</p>

      <input
        type="text"
        placeholder="Filter by name..."
        value={nameFilter}
        onChange={handleFilterChange}
        className="filter-input"
      />
      {participantId && (
        <a href="/participantsPage" target="_top" rel="noopener noreferrer" className="view-all-link">
          View all participants
        </a>
      )}

      <ul className="people-list">
        {participants.length > 0 ? (
          participants.map(p => (
            <li key={p.id} className="person-item">
              <div className="person-card">
                <img 
                  src={`${API_URL}/upload/${p.image}`} 
                  alt={p.name} 
                  className="person-image" 
                  onClick={() => handleImageClick(`${API_URL}/upload/${p.image}`)}
                />
                <div className="person-info">
                  <h2>{p.name}</h2>
                  <p>Likes: {p.like_count}</p>
                  <button 
                    className="like-button" 
                    onClick={() => handleLikeToggle(p.id)}
                    style={{ backgroundColor: p.liked ? '#ddd' : 'transparent' }}
                  >
                   <img 
    src={p.liked ? likeImg : unlikeImg} 
    alt={p.liked ? 'Unlike' : 'Like'} 
    style={{ width: 24, height: 24 }} 
  />
                  </button>
                  <a 
                    href={generateWhatsAppLink(p)} 
                    className="whatsapp-share-button" 
                    target="_blank" 
                    rel="noopener noreferrer"
                  >
                    Share on WhatsApp
                  </a>
                </div>
              </div>
            </li>
          ))
        ) : (
          <p>No participants found</p>
        )}
      </ul>

      <div className="pagination-controls">
        <button 
          onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} 
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span>Page {currentPage} of {totalPages}</span>
        <button 
          onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))} 
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>

      {zoomedImage && (
        <div className="zoomed-image-modal" onClick={closeZoomedImage}>
          <img src={zoomedImage} alt="Zoomed" className="zoomed-image" />
        </div>
      )}
    </div>
  );
}

export default ParticipantsPage;
