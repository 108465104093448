import React, { useState, useEffect } from 'react';
import '../EmailVerification.css'; // Import CSS file for styling
import APIURL from '../config'; // Import the global API URL
import queryString from 'query-string'; // Import query-string for parsing URL parameters

const EmailVerification = () => {
  const [token, setToken] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true); // Start loading on component mount

  // Use useEffect to read token from URL when component mounts
  useEffect(() => {
    const { token: urlToken } = queryString.parse(window.location.search);
    if (urlToken) {
      setToken(urlToken); // Set token from URL to state
      verifyEmail(urlToken); // Verify email with the token
    } else {
      setMessage('Invalid or missing verification token.');
      setIsLoading(false);
    }
  }, []);

  const verifyEmail = async (token) => {
    try {
      const response = await fetch(`${APIURL}/verify-email`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'ubpatil' // Add this if your API needs it
        },
        body: JSON.stringify({ token }) // Pass the token in the request body
      });
      
      const result = await response.text();
  
      if (!response.ok) {
        setMessage(`Error: ${result}`);
      } else {
        setMessage(result);
      }
    } catch (error) {
      console.error('Error verifying email:', error);
      setMessage('An error occurred while verifying your email.');
    } finally {
      setIsLoading(false);
    }
  };
  
  
  return (
    <div className="email-verification-container">
      <h1>Email Verification</h1>
      <p id="status">
        {isLoading ? 'Verifying your email...' : message}
      </p>
    </div>
  );
};

export default EmailVerification;
