import React, { useState, useEffect, useRef } from 'react'; 
import Logout from './Logout';
import { Link, useLocation } from 'react-router-dom';
import '../Navbar.css'; // Ensure this import is correct
import logo from '../Images/logo.png'; 
function Navbar() {
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('authToken'));
  const [isAdmin, setIsAdmin] = useState(localStorage.getItem('isAdmin') === 'true');
  const [username, setUsername] = useState(localStorage.getItem('username') || ''); // Fetch username from localStorage
  const location = useLocation();
  const navbarTogglerRef = useRef(null);

  useEffect(() => {
    const handleStorageChange = () => {
      setIsLoggedIn(!!localStorage.getItem('authToken'));
      setIsAdmin(localStorage.getItem('isAdmin') === 'true');
      setUsername(localStorage.getItem('username') || ''); // Update username on storage change
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  useEffect(() => {
    // Hide the mobile menu when a link is clicked
    const collapseNavbar = () => {
      if (navbarTogglerRef.current) {
        const collapseElement = document.getElementById('navbarNav');
        if (collapseElement && collapseElement.classList.contains('show')) {
          collapseElement.classList.remove('show');
        }
      }
    };

    collapseNavbar();
  }, [location]);

  const getNavLinkClass = (path) => {
    return location.pathname === path ? 'nav-link active' : 'nav-link';
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light" style={{ backgroundColor: 'rgb(255, 165, 0)' }}>
      <Link className="navbar-brand" to="/">
      <img src={logo} alt="Logo" style={{ height: '150px', width: '250px', marginLeft: '20px' }} 
      />
      </Link>
      <button 
        className="navbar-toggler" 
        type="button" 
        data-bs-toggle="collapse" 
        data-bs-target="#navbarNav" 
        aria-controls="navbarNav" 
        aria-expanded="false" 
        aria-label="Toggle navigation"
        ref={navbarTogglerRef}
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarNav">
        <ul className="navbar-nav ms-auto">
        <li className="nav-item">
        <Link className={getNavLinkClass('/mobile-app')} to="/mobile-app">
              Download App
            </Link>
          </li>
          <li className="nav-item">
            <Link className={getNavLinkClass('/list')} to="/list">
              Enquiry Now
            </Link>
          </li>
          <li className="nav-item">
            <Link className={getNavLinkClass('/uploadPage')} to="/uploadPage">
             Upload Bappa
            </Link>
          </li>
          <li className="nav-item">
            <Link className={getNavLinkClass('/participantsPage')} to="/participantsPage">
            Participants
            </Link>
          </li>
          <li className="nav-item">
            <Link className={getNavLinkClass('/topContestantsPage')} to="/topContestantsPage">
            Top Contestants 
            </Link>
          </li>
          <li className="nav-item">
            <Link className={getNavLinkClass('/about')} to="/about">
              About Us
            </Link>
          </li>
          <li className="nav-item">
            <Link className={getNavLinkClass('/esign')} to="/esign">
            LetterPage
            </Link>
          </li>
          {isAdmin && (
            <li className="nav-item">
              <Link className={getNavLinkClass('/admin/dashboard')} to="/admin/dashboard">
                Admin Panel
              </Link>
            </li>
          )}
          {!isLoggedIn ? (
            <li className="nav-item">
              <Link className={getNavLinkClass('/login')} to="/login">
                Login/Signup
              </Link>
            </li>
          ) : (
            <>
              <li className="nav-item">
                <Link className={getNavLinkClass('/cart')} to="/cart">
                  <div className="cart-text">Cart</div>
                </Link>
              </li>
              <li className="nav-item">
                <Link className={getNavLinkClass('/admin/view-my-orders')} to="/admin/view-my-orders">
                  My Enquiry
                </Link>
              </li>
              <li className="nav-item">
                <Logout />
              </li>
            </>
          )}
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
