// Breadcrumbs.js
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../Breadcrumbs.css'; // Import the CSS file

const Breadcrumbs = () => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter(x => x);

  // Default breadcrumb trail when no pathnames are present
  const defaultBreadcrumbs = [
    { label: 'Home', href: '/' },
    { label: 'List', href: '/product' }
  ];

  // Generate breadcrumb items based on pathnames
  const breadcrumbItems = pathnames.length === 0
    ? defaultBreadcrumbs
    : [
        { label: 'Home', href: '/' },
        ...pathnames.map((pathname, index) => {
          const to = `/${pathnames.slice(0, index + 1).join('/')}`;
          return {
            label: pathname.charAt(0).toUpperCase() + pathname.slice(1),
            href: to
          };
        })
      ];

  return (
    <nav style={{ marginLeft: '10px' }}>
      <ol className="breadcrumb">
        {breadcrumbItems.map((item, index) => (
          <li key={item.href} className="breadcrumb-item">
            {index === breadcrumbItems.length - 1 ? (
              <span>{item.label}</span>
            ) : (
              <Link to={item.href}>{item.label}</Link>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
