import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './CSS/ReportPage.css'; // Custom CSS file
import APIURL from '../../config.js';

function ReportPage() {
  const [reportData, setReportData] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  useEffect(() => {
    if (startDate && endDate) {
      fetchReportData();
    }
  }, [startDate, endDate]);

  const fetchReportData = async () => {
    try {
      const response = await axios.get(`${APIURL}/report`,{headers: {'Authorization': 'ubpatil'}}, {
        params: { startDate, endDate }
      });
      setReportData(response.data);
    } catch (error) {
      console.error('Error fetching report data:', error);
    }
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    if (name === 'startDate') {
      setStartDate(value);
    } else if (name === 'endDate') {
      setEndDate(value);
    }
  };

  return (
    <div className="report-page">
      <h1>Enquiry Report</h1>
      <div className="date-filters">
        <label>
          Start Date:
          <input type="date" name="startDate" value={startDate} onChange={handleDateChange} />
        </label>
        <label>
          End Date:
          <input type="date" name="endDate" value={endDate} onChange={handleDateChange} />
        </label>
        <button onClick={fetchReportData}>
          Generate Report
        </button>
      </div>

      {reportData && (
        <div className="report-data">
          <h2>Total Orders: {reportData.totalOrders}</h2>
          <h2>Total Amount: ₹{reportData.totalAmount.toFixed(2)}</h2>
          <h3>Product Amounts:</h3>
          <table className="amount-table">
            <thead>
              <tr>
                <th>Product ID</th>
                <th>Product Name</th>
                <th>Total Amount (INR)</th>
              </tr>
            </thead>
            <tbody>
              {reportData.products.map(product => (
                <tr key={product.product_id}>
                  <td>{product.product_id}</td>
                  <td>{product.product_name}</td>
                  <td>₹{product.total_amount_inr.toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default ReportPage;
