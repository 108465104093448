import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import Filter from './Filters'; // Updated import

import ProductCard from './ProductCard';
import { useNavigate } from 'react-router-dom';
import APIURL from '../config';
import '../App.css';
import { Helmet } from 'react-helmet';

function MainContainer() {
    const [products, setProducts] = useState([]);
    const [sizes, setSizes] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState({ size: [], price: { min: '', max: '' } });
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState('');
    const [cart, setCart] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    // Retrieve userId from localStorage
    const userId = localStorage.getItem('userId');

    // Fetch products from the API
    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await axios.get(`${APIURL}/products`,{headers: {'Authorization': 'ubpatil'},mode: 'no-cors'});
                setProducts(response.data);
            } catch (error) {
                console.error('Error fetching products:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchProducts();
    }, []);

    // Fetch cart items from the API
    useEffect(() => {
        const fetchCart = async () => {
            try {
                const response = await axios.get(`${APIURL}/cart/${userId}`,{headers: {'Authorization': 'ubpatil'},mode: 'no-cors'});
                setCart(response.data);
            } catch (error) {
                console.error('Error fetching cart:', error);
            }
        };
        fetchCart();
    }, [userId, navigate]);

    // Fetch dynamic filters from the API
    useEffect(() => {
        const fetchFilters = async () => {
            try {
                const response = await axios.get(`${APIURL}/sizes`,{headers: {'Authorization': 'ubpatil'},mode: 'no-cors'});
                setSizes(response.data);
            } catch (error) {
                console.error('Error fetching filters:', error);
            }
        };
        
        fetchFilters();
    }, []);

    // Handle filter change
    const handleFilterChange = useCallback((filterName, filterValue, isChecked) => {
        setSelectedFilters(prevFilters => {
            const newFilters = { ...prevFilters };
            if (filterName === 'price') {
                newFilters.price = filterValue;
            } else {
                if (isChecked) {
                    newFilters[filterName].push(filterValue);
                } else {
                    newFilters[filterName] = newFilters[filterName].filter(value => value !== filterValue);
                }
            }
            return newFilters;
        });
        // Reset pagination when filters change
        setCurrentPage(1);
    }, []);

    // Handle adding product to cart
    const handleAddToCart = useCallback((productId) => {
        if (!userId) {
            navigate('/login'); // Redirect to login if no userId
            return;
        }

        axios.post(`${APIURL}/cart`, {
            userId: userId,
            productId: productId,
            quantity: 1
        },{headers: {'Authorization': 'ubpatil'},mode: 'no-cors'})
            .then(response => {
               
                setCart(prevCart => [...prevCart, response.data]);
                navigate('/cart'); // Navigate to CartPage
            })
            .catch(error => {
                console.error('Error adding to cart:', error);
            });
    }, [navigate, userId]);

    // Filter products based on selected filters
    const filteredProducts = products.filter(product => {
        const sizeMatch = selectedFilters.size.length === 0 || selectedFilters.size.includes(product.size);
        const priceMatch = (!selectedFilters.price.min || product.price >= selectedFilters.price.min) &&
            (!selectedFilters.price.max || product.price <= selectedFilters.price.max);
        return sizeMatch && priceMatch;
    });

    // Paginate products
    const paginatedProducts = filteredProducts.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    // Search products
    const searchedProducts = paginatedProducts.filter(product =>
        product.title.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // Calculate total pages for pagination
    const totalPages = Math.ceil(filteredProducts.length / itemsPerPage);

    // Handle page change
    const handlePageChange = pageNumber => {
        setCurrentPage(pageNumber);
    };

    // Dynamic filters
    const filters = {
        sizes: sizes,
        // No idolNames included here
    };

    return (
        <div className="container">
            <Helmet>
                <title>Product Page - Ganapati Bappa Moraya</title>
                <meta name="description" content="Browse our collection of Ganapati Bappa Moraya idols. Filter by size and price. Shop now for the best quality products." />
            </Helmet>
            {loading && <div>Loading...</div>}
            <div className="row">
                <div className="col-md-3">
                    <Filter filters={filters} onFilterChange={handleFilterChange} />
                </div>
                <div className="col-md-9">
                    <div className="search-container">
                        <input
                            type="text"
                            placeholder="Search List..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </div>
                    <div className="row">
                        {searchedProducts.length > 0 ? (
                            searchedProducts.map(product => (
                                <ProductCard
                                    key={product.id}
                                    id={product.id}
                                    likes={product.likes}
                                    image={`${APIURL}/Images/${product.id}.jpg`} // Update image path here
                                    title={product.title}
                                    price={`₹${product.price}`} // Price in INR
                                    onAddToCart={() => handleAddToCart(product.id)}
                                />
                            ))
                        ) : (
                            <div>No products found.</div>
                        )}
                    </div>
                    <div className="pagination">
                        {Array.from({ length: totalPages }, (_, index) => (
                            <button
                                key={index + 1}
                                onClick={() => handlePageChange(index + 1)}
                                className={currentPage === index + 1 ? 'active' : ''}
                            >
                                {index + 1}
                            </button>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MainContainer;
