import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar'; 
import Footer from './components/Footer';
import MainContainer from './components/MainContainer';
import CartPage from './components/CartPage';
import ContactForm from './components/ContactForm';
import AboutUs from './components/AboutUs'; 
import LoginPage from './components/LoginPage';
import SignupPage from './components/SignupPage';
import AdminDashboard from './components/Admin/AdminDashboard';  
import AddProduct from './components/Admin/AddProduct';
import ProductList from './components/Admin/ProductList';
import UserList from './components/Admin/UserList';
import ContactPanel from './components/Admin/ContactPanel';
import OrderDetails from './components/Admin/OrderDetails';
import ViewOrders from './components/Admin/ViewOrders';
import ReportPage from './components/Admin/ReportPage'; 
import Breadcrumbs from './components/Breadcrumbs'; // Example component
import ResetPassword from './components/ResetPassword'; // Make sure the path is correct
import EmailVerification from './components/EmailVerification'; // Make sure the path is correct
import AuthWrapper from './components/Admin/AuthWrapper'; // Import AuthWrapper
import AdminPage from './components/Admin/AdminPage'; // Import AdminPage
import UploadProduct from './components/Admin/UploadProduct';
import UploadPage from './components/UploadPage';
import ParticipantsPage from './components/ParticipantsPage';
import TopContestantsPage from './components/TopContestantsPage'; // Correct import path 
import LetterPage from './components/LetterPage'; // Correct import path 
import 'bootstrap/dist/css/bootstrap.min.css'; 
import './App.css'; 
import MobileAppPage from './components/MobileAppPage';

function App() {
  return (
    <Router>
      <div>
        <Navbar />
        <Breadcrumbs />
        <Routes>
          <Route path="/" element={<ParticipantsPage />} />
          <Route path="/cart" element={<CartPage />} />
          <Route path="/contact" element={<ContactForm />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/list" element={<MainContainer />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignupPage />} />  
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/verify-email" element={<EmailVerification />} /> 
          <Route path="/uploadPage" element={<UploadPage />} />
          <Route path="/participantsPage" element={<ParticipantsPage />} />
          <Route path="/esign" element={<LetterPage />} />
          <Route path="/topContestantsPage" element={<TopContestantsPage />} />
          <Route path="/mobile-app" element={<MobileAppPage />} />
          <Route path="/admin/dashboard" element={<AuthWrapper><AdminDashboard /></AuthWrapper>} />
                <Route path="/admin/dashboard/add-product" element={<AuthWrapper><AddProduct /></AuthWrapper>} />
                <Route path="/admin/dashboard/upload-product" element={<AuthWrapper><UploadProduct /></AuthWrapper>} />
                <Route path="/admin/dashboard/view-orders" element={<AuthWrapper><OrderDetails /></AuthWrapper>} />
                <Route path="/admin/dashboard/reports" element={<AuthWrapper><ReportPage /></AuthWrapper>} />
                <Route path="/admin/dashboard/product-list" element={<AuthWrapper><ProductList /></AuthWrapper>} />
                <Route path="/admin/dashboard/user-list" element={<AuthWrapper><UserList /></AuthWrapper>} />
                <Route path="/admin/dashboard/contact-list" element={<AuthWrapper><ContactPanel /></AuthWrapper>} />
                <Route path="/admin/dashboard/adminpage" element={<AuthWrapper><AdminPage /></AuthWrapper>} /> 
                <Route path="/invitation" element={<ContactPanel />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
