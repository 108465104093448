import React from 'react';
import './CSS/ImageModal.css'; // Custom CSS for modal styling

function ImageModal({ imageSrc, isOpen, onClose }) {
    if (!isOpen) return null;

    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <span className="modal-close" onClick={onClose}>&times;</span>
                <div className="modal-image-wrapper">
                    <img src={imageSrc} alt="Full Size" className="modal-image" />
                </div>
            </div>
        </div>
    );
}

export default ImageModal;
