// Filter.js
import React, { useState, useEffect } from 'react';
import '../Filters.css';

const Filter = ({ filters, onFilterChange }) => {
    const [priceRange, setPriceRange] = useState([500, 100000]);
    const [expandedSections, setExpandedSections] = useState({
        size: false,
        idolName: false,
        price: false,
    });

    useEffect(() => {
        if (priceRange[0] !== undefined && priceRange[1] !== undefined) {
            onFilterChange('price', { min: priceRange[0], max: priceRange[1] });
        }
    }, [priceRange, onFilterChange]);

    const handleSliderChange = (min, max) => {
        setPriceRange([min, max]);
    };

    const toggleSection = (section) => {
        setExpandedSections(prev => ({
            ...prev,
            [section]: !prev[section],
        }));
    };

    return (
        <div className="filter-container">
            <h3>Filters</h3>
            <div className={`filter-section ${expandedSections.size ? 'expanded' : ''}`}>
                <h4 onClick={() => toggleSection('size')}>Size</h4>
                <div className="filter-content">
                    {filters.sizes && filters.sizes.map(size => (
                        <label key={size}>
                            <input
                                type="checkbox"
                                onChange={(e) => onFilterChange('size', size, e.target.checked)}
                            />
                            {size}
                        </label>
                    ))}
                </div>
            </div>
             
            <div className={`filter-section ${expandedSections.price ? 'expanded' : ''}`}>
                <h4 onClick={() => toggleSection('price')}>Price</h4>
                <div className="filter-content">
                    <div className="slider-container">
                        <input
                            type="range"
                            min="500"
                            max="10000"
                            step="100"
                            value={priceRange[0]}
                            onChange={(e) => handleSliderChange(e.target.value, priceRange[1])}
                        />
                        <input
                            type="range"
                            min="500"
                            max="100000"
                            step="100"
                            value={priceRange[1]}
                            onChange={(e) => handleSliderChange(priceRange[0], e.target.value)}
                        />
                        <div className="price-range">
                            <span>₹{priceRange[0]}</span> - <span>₹{priceRange[1]}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Filter;
