import React from 'react';
import { useNavigate } from 'react-router-dom';

function Logout() {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Clear localStorage
    localStorage.removeItem('authToken');
    localStorage.removeItem('userId');
    localStorage.removeItem('isAdmin');

    // Force re-render of Navbar by triggering a storage event
    window.dispatchEvent(new Event('storage'));

    // Redirect to login or homepage
    navigate('/login');
  };

  return (
    <button onClick={handleLogout} className="btn btn-primary">
      Logout
    </button>
  );
}

export default Logout;
