import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import likeImg from '../Images/like.svg';
import unlikeImg from '../Images/unlike.svg';
import whatsaap from '../Images/whatsup.png';
import trophyImg from '../Images/trophy.png'; // Import the trophy image
import API_URL from '../config';
import '../ParticipantsPage.css';

function TopContestantsPage() {
  const [participants, setParticipants] = useState([]);
  const [totalParticipants, setTotalParticipants] = useState(0);
  const [userId, setUserId] = useState(localStorage.getItem('userId'));
  const [currentPage, setCurrentPage] = useState(1);
  const [participantsPerPage] = useState(5);
  const [zoomedImage, setZoomedImage] = useState(null);
  const [nameFilter, setNameFilter] = useState('');
  const [countdown, setCountdown] = useState('');

  const navigate = useNavigate();
  const location = useLocation();

  // Countdown target to September 18, 2024, 12:00:25
  const countdownTarget = new Date('Sep 18, 2024 12:00:25').getTime();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const participantId = queryParams.get('participantId');

    const fetchParticipants = () => {
      axios
        .get(`${API_URL}/top-participants?page=${currentPage}&limit=${participantsPerPage}&participantId=${participantId || ''}&name=${nameFilter}`, {
          headers: {
            'Authorization': 'ubpatil',
          }
        })
        .then((response) => {
          setParticipants(response.data.topParticipants || []);
          setTotalParticipants(response.data.totalParticipants || 0);
        })
        .catch((error) => console.error('Error fetching participants:', error));
    };

    fetchParticipants();
  }, [currentPage, participantsPerPage, location.search, nameFilter]);

  // Countdown script
  useEffect(() => {
    const countdownInterval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countdownTarget - now;

      if (distance > 0) {
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        
        setCountdown(`${days}d ${hours}h ${minutes}m ${seconds}s`);
      } else {
        setCountdown("EXPIRED");
        clearInterval(countdownInterval);
      }
    }, 1000);

    return () => clearInterval(countdownInterval); // Clear the interval on component unmount
  }, [countdownTarget]);

  const handleFilterChange = (e) => {
    setNameFilter(e.target.value);
    setCurrentPage(1);
  };

  const handleLikeToggle = async (participantId) => {
    if (!userId) {
      navigate('/login');
      return;
    }

    try {
      const response = await axios.post(`${API_URL}/like/${participantId}`, {}, {
        headers: {
          'Authorization': 'ubpatil',
          'user-id': userId,
        }
      });

      if (response.data.message === 'Like recorded') {
        setParticipants(prevParticipants =>
          prevParticipants.map(participant =>
            participant.id === participantId
              ? { ...participant, like_count: participant.like_count + 1, liked: true }
              : participant
          )
        );
      } else if (response.data.message === 'Like removed') {
        setParticipants(prevParticipants =>
          prevParticipants.map(participant =>
            participant.id === participantId
              ? { ...participant, like_count: participant.like_count - 1, liked: false }
              : participant
          )
        );
      }
    } catch (error) {
      console.error('Error toggling like:', error);
    }
  };

  const handleImageClick = (image) => {
    setZoomedImage(image);
  };

  const closeZoomedImage = () => {
    setZoomedImage(null);
  };

  const totalPages = Math.ceil(totalParticipants / participantsPerPage);

  return (
    <div className="people-container">
      {/* Countdown at the top */}
      <div className="countdown-container">
        <h2>Countdown to September 18, 2024:</h2>
        <p id="demo" className="countdown-timer">{countdown}</p>
      </div>
  
      <h1>Top 5 Contestants</h1>
      <p>
        This contest celebrates the top 5 participants with the highest likes.
        Support your favorites by liking their profiles and sharing their links!
      </p>
      <p className="warning">
        Please do not enter fake mobile numbers or email IDs. We verify the data,
        and any fake information will be removed, along with the participant.
      </p>
  
      <input
        type="text"
        placeholder="Filter by name..."
        value={nameFilter}
        onChange={handleFilterChange}
        className="filter-input"
      />
  
      <ul className="people-list">
        {participants.length > 0 ? (
          participants.map((p, index) => (
            <li key={p.id} className="person-item">
              <div className="person-card">
                {/* Display trophy for top 5 participants */}
                {index < 5 && (
                  <img 
                    src={trophyImg} 
                    alt="Trophy" 
                    className="trophy-icon" 
                  />
                )}
                <img 
                  src={`${API_URL}/upload/${p.image}`} 
                  alt={p.name} 
                  className="person-image" 
                  onClick={() => handleImageClick(`${API_URL}/upload/${p.image}`)}
                />
                <div className="person-info">
                  <h2>{p.name}</h2>
                  <p>Likes: {p.like_count}</p>
                </div>
              </div>
            </li>
          ))
        ) : (
          <p>No participants found</p>
        )}
      </ul>
  
      {zoomedImage && (
        <div className="zoomed-image-modal" onClick={closeZoomedImage}>
          <img src={zoomedImage} alt="Zoomed" className="zoomed-image" />
        </div>
      )}
    </div>
  );
}  
export default TopContestantsPage;
