import React, { useState, useEffect } from 'react';
import axios from 'axios';
import APIURL from '../config';
import { useNavigate } from 'react-router-dom';
import '../App.css'; // Import the CSS file
import '../CartPage.css'; // Import the specific CSS file

function UserAddressForm() {
  const [userData, setUserData] = useState({});
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [address, setAddress] = useState('');
  const [pincode, setPincode] = useState('');
  const [orderSuccess, setOrderSuccess] = useState(false); // State for modal visibility
  const [errors, setErrors] = useState({}); // State for form validation errors
  const userId = localStorage.getItem('userId');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get(`${APIURL}/countries`, {
          headers: { 'Authorization': 'ubpatil' }
        });
        setCountries(response.data);
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };

    fetchCountries();
  }, []);

  useEffect(() => {
    const fetchStates = async () => {
      if (selectedCountry) {
        try {
          const response = await axios.get(`${APIURL}/states`, {
            headers: { 'Authorization': 'ubpatil' },
            params: { countryId: selectedCountry }
          });
          setStates(response.data);
          setSelectedState(''); // Reset state
          setCities([]); // Reset cities
        } catch (error) {
          console.error('Error fetching states:', error);
        }
      }
    };

    fetchStates();
  }, [selectedCountry]);

  useEffect(() => {
    const fetchCities = async () => {
      if (selectedState) {
        try {
          const response = await axios.get(`${APIURL}/cities`, {
            headers: { 'Authorization': 'ubpatil' },
            params: { stateId: selectedState }
          });
          setCities(response.data);
        } catch (error) {
          console.error('Error fetching cities:', error);
        }
      }
    };

    fetchCities();
  }, [selectedState]);

  useEffect(() => {
    const fetchUserData = async () => {
      if (userId) {
        try {
          const response = await axios.get(`${APIURL}/user/${userId}`, {
            headers: { 'Authorization': 'ubpatil' }
          });
          const { country, state, city, address, pinCode } = response.data;

          setUserData(response.data);
          setAddress(address || '');
          setPincode(pinCode || '');

          setSelectedCountry(country || '');
          if (country) {
            const statesResponse = await axios.get(`${APIURL}/states`, {
              headers: { 'Authorization': 'ubpatil' },
              params: { countryId: country }
            });
            setStates(statesResponse.data);

            setSelectedState(state || '');
            if (state) {
              const citiesResponse = await axios.get(`${APIURL}/cities`, {
                headers: { 'Authorization': 'ubpatil' },
                params: { stateId: state }
              });
              setCities(citiesResponse.data);
              setSelectedCity(city || '');
            }
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    };

    fetchUserData();
  }, [userId]);

  const validateFields = () => {
    const newErrors = {};
    if (!address.trim()) newErrors.address = 'Address is required.';
    if (!pincode.trim()) newErrors.pincode = 'Pincode is required.';
    if (!selectedCountry) newErrors.country = 'Country is required.';
    if (!selectedState) newErrors.state = 'State is required.';
    if (!selectedCity) newErrors.city = 'City is required.';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleAddressUpdate = async () => {
    try {
      const addressData = {
        userId,
        address,
        pinCode: pincode,
        city: selectedCity,
        state: selectedState,
        country: selectedCountry
      };

      await axios.put(`${APIURL}/update-address`, addressData, {
        headers: { 'Authorization': 'ubpatil' }
      });
    } catch (error) {
      console.error('Error updating address:', error);
      alert('Failed to update address.');
    }
  };

  const handleOrderConfirmation = async () => {
    if (!validateFields()) return;

    try {
      await handleAddressUpdate(); // Update address first

      await axios.post(`${APIURL}/confirm-order`, { userId }, {
        headers: { 'Authorization': 'ubpatil' }
      });

      // Show success modal
      setOrderSuccess(true);
    } catch (error) {
      console.error('Error confirming order:', error);
      alert('Failed to confirm Enquiry.');
    }
  };

  const handleClose = () => {
    setOrderSuccess(false); // Close modal
    navigate('/bappa'); // Redirect to /products
  };

  return (
    <div className="address-form-container">
      <h2>Update Address</h2>
      <form className="address-form" onSubmit={(e) => e.preventDefault()}>
        <div className="form-group">
          <label htmlFor="address">Address:</label>
          <input
            type="text"
            id="address"
            value={address || ''}
            onChange={(e) => setAddress(e.target.value)}
            placeholder="Enter your address"
          />
          {errors.address && <p className="error">{errors.address}</p>}
        </div>
        <div className="form-group">
          <label htmlFor="pincode">Pincode:</label>
          <input
            type="text"
            id="pincode"
            value={pincode || ''}
            onChange={(e) => setPincode(e.target.value)}
            placeholder="Enter your pincode"
          />
          {errors.pincode && <p className="error">{errors.pincode}</p>}
        </div>
        <div className="form-group">
          <label htmlFor="country">Country:</label>
          <select
            id="country"
            value={selectedCountry}
            onChange={(e) => setSelectedCountry(e.target.value)}
          >
            <option value="">Select Country</option>
            {countries.map(c => (
              <option key={c.id} value={c.id}>{c.name}</option>
            ))}
          </select>
          {errors.country && <p className="error">{errors.country}</p>}
        </div>
        <div className="form-group">
          <label htmlFor="state">State:</label>
          <select
            id="state"
            value={selectedState}
            onChange={(e) => setSelectedState(e.target.value)}
            disabled={!selectedCountry}
          >
            <option value="">Select State</option>
            {states.map(s => (
              <option key={s.id} value={s.id}>{s.name}</option>
            ))}
          </select>
          {errors.state && <p className="error">{errors.state}</p>}
        </div>
        <div className="form-group">
          <label htmlFor="city">City:</label>
          <select
            id="city"
            value={selectedCity}
            onChange={(e) => setSelectedCity(e.target.value)}
            disabled={!selectedState}
          >
            <option value="">Select City</option>
            {cities.map(c => (
              <option key={c.id} value={c.id}>{c.name}</option>
            ))}
          </select>
          {errors.city && <p className="error">{errors.city}</p>}
        </div>
        <button type="button" onClick={handleOrderConfirmation}>Confirm Enquiry</button>
      </form>

      {/* Modal for order success */}
      {orderSuccess && (
        <div className="cart-modal">
          <div className="cart-modal-content">
            <span className="cart-modal-close" onClick={() => setOrderSuccess(false)}>&times;</span>
            <h4>Enquiry placed successfully!</h4>
            <p style={{ color: 'green' }}>
  Your enquiry has been placed. Thank you for contacting us.
</p>
            <button onClick={handleClose} className="cart-btn cart-btn-primary">Close</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default UserAddressForm;
