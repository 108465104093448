// src/config.js
import axios from 'axios';


const APIURL = 'https://nodeapi.yuvalink.com/api';
//const APIURL = 'http://localhost:5000/api';
axios.defaults.baseURL = APIURL;
axios.defaults.headers.common['Authorization'] = 'ubpatil'; // Use your authorization token here



export default APIURL;

