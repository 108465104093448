// ContactForm.js
import React, { useState } from 'react'; 
import axios from 'axios';
import '../ContactForm.css';
import APIURL from '../config'; // Import the global API URL

function ContactForm() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [message, setMessage] = useState('');
  const [alert, setAlert] = useState({ type: '', text: '' });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setAlert({ type: '', text: '' });

    if (!name || !email || !mobile || !message) {
      setAlert({ type: 'danger', text: 'All fields are required!' });
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setAlert({ type: 'danger', text: 'Invalid email address!' });
      return;
    }

    const mobileRegex = /^[0-9]{10}$/;
    if (!mobileRegex.test(mobile)) {
      setAlert({ type: 'danger', text: 'Invalid mobile number!' });
      return;
    }

    try {
      await axios.post(`${APIURL}/contact`, { name, email, mobile, message },{headers: {'Authorization': 'ubpatil'}});
      setAlert({ type: 'success', text: 'Your message has been sent successfully!' });
      setName('');
      setEmail('');
      setMobile('');
      setMessage('');
    } catch (error) {
      console.error('Error sending message:', error);
      setAlert({ type: 'danger', text: 'Failed to send message. Please try again later.' });
    }
  };

  return (
    <div className="contact-form"> 
      <h2>Contact Us</h2>
      {alert.text && (
        <div className={`alert alert-${alert.type}`}>
          {alert.text}
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="mobile">Mobile:</label>
          <input
            type="text"
            id="mobile"
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="message">Message:</label>
          <textarea
            id="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="btn btn-primary">Send Message</button>
      </form>
    </div>
  );
}

export default ContactForm;
