import React, { useState, useEffect } from 'react';
import '../ResetPassword.css'; // Import CSS file for styling
import APIURL from '../config'; // Import the global API URL
import queryString from 'query-string'; // Import query-string for parsing URL parameters

const ResetPassword = () => {
  const [token, setToken] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false); // State to handle loading

  // Use useEffect to read token from URL when component mounts
  useEffect(() => {
    const { token: urlToken } = queryString.parse(window.location.search);
    if (urlToken) {
      setToken(urlToken); // Set token from URL to state
    } else {
      setMessage('Invalid or missing reset token.');
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Start loading

    try {
      const response = await fetch(`${APIURL}/reset-password`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'ubpatil' // Add this if your API needs it
        },
        body: JSON.stringify({ token, newPassword })
      });

      if (!response.ok) {
        // Handle errors based on response status
        const errorText = await response.text();
        setMessage(`Error: ${errorText}`);
      } else {
        const result = await response.text();
        setMessage(result);
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage('Error resetting password.');
    } finally {
      setIsLoading(false); // End loading
    }
  };

  return (
    <div className="form-container">
      <h2>Reset Password</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="token">Reset Token:</label>
          <input
            type="text"
            id="token"
            value={token}
            onChange={(e) => setToken(e.target.value)}
            required
            disabled // Disable the input field
          />
        </div>
        <div className="form-group">
          <label htmlFor="newPassword">New Password:</label>
          <input
            type="password"
            id="newPassword"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" disabled={isLoading}>
          {isLoading ? 'Resetting...' : 'Reset Password'}
        </button>
        {message && (
          <p className="message">
            {message} {message.includes('successfully') && <a href="/login">Login Now</a>}
          </p>
        )}
      </form>
    </div>
  );
};

export default ResetPassword;
