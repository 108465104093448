import React, { useState, useEffect } from 'react';
import axios from 'axios';
import APIURL from '../../config'; // Ensure this points to your backend URL
import './CSS/ProductList.css';

const ProductList = () => {
    const [products, setProducts] = useState([]);
    const [sort, setSort] = useState('price_asc'); // Default sorting
    const [filter, setFilter] = useState({});
    const [pagination, setPagination] = useState({ page: 1, limit: 20, total: 0 });
    const [selectedProduct, setSelectedProduct] = useState(null); // State for selected product
    const [selectedImage, setSelectedImage] = useState(null); // State for selected image

    useEffect(() => {
        fetchProducts();
    }, [sort, filter, pagination.page, pagination.limit]);

    const fetchProducts = async () => {
        try {
            const response = await axios.get(`${APIURL}/productList`, {
                params: {
                    sort,
                    ...filter,
                    page: pagination.page,
                    limit: pagination.limit
                },
                headers: { 
                    'Authorization': 'ubpatil' 
                }
            });
            setProducts(response.data.products);
            // Ensure the API response contains pagination data
            setPagination({
                page: response.data.pagination.page,
                limit: response.data.pagination.limit,
                total: response.data.pagination.total
            });
        } catch (error) {
            console.error('Error fetching products:', error);
        }
    };

    const handleSortChange = (e) => {
        setSort(e.target.value);
    };

    const handleFilterChange = (e) => {
        setFilter({ ...filter, [e.target.name]: e.target.value });
    };

    const handlePageChange = (page) => {
        if (page < 1 || page > Math.ceil(pagination.total / pagination.limit)) return;
        setPagination(prev => ({ ...prev, page }));
    };

    const handleProductDeactivation = async (productId, isActive) => {
        try {
            await axios.put(`${APIURL}/deactivateProduct`, 
                { id: productId, isActive: !isActive },
                { headers: { 'Authorization': 'ubpatil' } }
            );
            fetchProducts(); // Refresh product list
        } catch (error) {
            console.error('Error updating product status:', error);
        }
    };
    
    const handleProductActivation = async (productId, isActive) => {
        try {
            await axios.put(`${APIURL}/activateProduct`, 
                { id: productId, isActive: !isActive },
                { headers: { 'Authorization': 'ubpatil' } }
            );
            fetchProducts(); // Refresh product list
        } catch (error) {
            console.error('Error updating product status:', error);
        }
    };

    const openProductModal = (product) => {
        setSelectedProduct(product);
    };

    const closeProductModal = () => {
        setSelectedProduct(null);
    };

    const openImageModal = (imageSrc) => {
        setSelectedImage(imageSrc);
    };

    const closeImageModal = () => {
        setSelectedImage(null);
    };

    return (
        <div className="product-list-container">
            <div className="filters">
                <select onChange={handleSortChange} value={sort}>
                    <option value="price_asc">Price Ascending</option>
                    <option value="price_desc">Price Descending</option>
                    <option value="name_asc">Name Ascending</option>
                    <option value="name_desc">Name Descending</option>
                </select>
                {/* Add more filters here */}
            </div>
            <div className="product-grid">
                {products.map(product => (
                    <div className="product-card" key={product.id}>
                        <div className="product-image" onClick={() => openImageModal(`${APIURL}/Images/${product.id}.jpg`)}>
                            <img src={`${APIURL}/Images/${product.id}.jpg`} alt={product.title} />
                        </div>
                        <div className="product-details">
                            <h3><a href="#" onClick={() => openProductModal(product)}>{product.title}</a></h3>
                            <p>Price: ₹{product.price}</p>
                            <button 
                                onClick={() => 
                                    product.isActive 
                                        ? handleProductDeactivation(product.id, product.isActive) 
                                        : handleProductActivation(product.id, product.isActive)
                                }
                            >
                                {product.isActive ? 'Deactivate' : 'Activate'}
                            </button>
                        </div>
                    </div>
                ))}
            </div>
            <div className="pagination">
                <button onClick={() => handlePageChange(pagination.page - 1)} disabled={pagination.page <= 1}>Previous</button>
                <span>Page {pagination.page} of {Math.ceil(pagination.total / pagination.limit)}</span>
                <button onClick={() => handlePageChange(pagination.page + 1)} disabled={pagination.page >= Math.ceil(pagination.total / pagination.limit)}>Next</button>
            </div>
            {selectedProduct && (
                <div className="modal-overlay" onClick={closeProductModal}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <button className="close-btn" onClick={closeProductModal}>X</button>
                        <h3>{selectedProduct.title}</h3>
                        <p>Price: ₹{selectedProduct.price}</p>
                        <p>Size: {selectedProduct.size}</p>
                        <p>Material: {selectedProduct.material}</p>
                        <p>Features: {selectedProduct.features}</p>
                        <p>Idol Name: {selectedProduct.idolName}</p>
                        <p>Rank: {selectedProduct.rank}</p>
                        <p>Likes: {selectedProduct.likes}</p>
                        {/* Add more product details as needed */}
                    </div>
                </div>
            )}
            {selectedImage && (
                <div className="modal-overlay" onClick={closeImageModal}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <button className="close-btn" onClick={closeImageModal}>X</button>
                        <img src={selectedImage} alt="Full Size" />
                    </div>
                </div>
            )}
        </div>
    );
};

export default ProductList;
