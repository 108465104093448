import React, { useState, useRef } from 'react';
import axios from 'axios';
import imageCompression from 'browser-image-compression';
import '../UploadPage.css'; // Import CSS file for styling
import APIURL from '../config'; // Import the global API URL

function UploadPage() {
  const [name, setName] = useState('');
  const [mobile, setMobile] = useState('');
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [mobileError, setMobileError] = useState(''); // State to handle mobile validation error
  const [showPopup, setShowPopup] = useState(false); // State to control the popup message
  const [isSubmitted, setIsSubmitted] = useState(false); // State to track if submission is successful
  const mobileInputRef = useRef(null); // Ref for focusing mobile input

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
  
    if (file) {
      const fileSizeInKB = file.size / 1024; // Convert file size to KB
  
      if (fileSizeInKB > 556) {
        try {
          // Compression options to ensure image is below 556KB
          const options = {
            maxSizeMB: 0.556, // Maximum file size in MB
            maxWidthOrHeight: 1024, // Adjust width/height as needed
            useWebWorker: true, // Use Web Worker for compression
          };
  
          // Compress the image if it exceeds 556KB
          const compressedFile = await imageCompression(file, options);
  
          setImage(compressedFile); // Use the compressed image
  
          // Generate a preview for the compressed image
          const reader = new FileReader();
          reader.onloadend = () => {
            setImagePreview(reader.result);
          };
          reader.readAsDataURL(compressedFile);
        } catch (error) {
          console.error('Error compressing the image:', error);
        }
      } else {
        // If the image size is already less than or equal to 556KB, set it directly
        setImage(file);
  
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreview(reader.result);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  // Mobile number validation using regex
  const validateMobile = (mobile) => {
    const mobileRegex = /^[6-9]\d{9}$/; // This is a simple validation for 10-digit mobile numbers starting with 6-9
    return mobileRegex.test(mobile);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate mobile number before submission
    if (!validateMobile(mobile)) {
      setMobileError('Invalid mobile number. Please enter a valid 10-digit number starting with 6-9.');
      mobileInputRef.current.focus(); // Focus on the mobile input field if validation fails
      return;
    } else {
      setMobileError(''); // Clear the error if validation passes
    }

    if (!isConfirmed) {
      alert('Please confirm the upload before submitting.');
      return;
    }

    const formData = new FormData();
    formData.append('name', name);
    formData.append('mobile', mobile);
    if (image) {
      formData.append('image', image);
    }

    try {
      const response = await axios.post(`${APIURL}/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': 'ubpatil', // Use your authorization token here
        },
      });

      // Hide the "Submit" button after successful submission
      setIsSubmitted(true);

      // Show popup message after successful submission
      setShowPopup(true);

      // Redirect to participantsPage after a short delay (6 seconds)
      setTimeout(() => {
        window.location.href = 'https://yuvalink.com/participantsPage';
      }, 6000);
    } catch (error) {
      if (error.response) {
        if (error.response.status === 409) {
          setMobileError(error.response.data.message || 'This mobile number has already been used.');
          mobileInputRef.current.focus();
        } else {
          console.error('Failed to upload:', error.response);
          alert(error.response.data.message || 'Upload failed. Please try again.');
        }
      } else if (error.request) {
        console.error('No response received:', error.request);
        alert('No response from server. Please try again.');
      } else {
        console.error('Error setting up request:', error.message);
        alert('Error occurred. Please try again.');
      }
    }
  };

  const handleConfirm = () => {
    setIsConfirmed(true);
    alert('Upload confirmed. You can now submit.');
  };

  return (
    <div className="upload-container">
      <h1>Ganpati Bappa Contest 2024: Upload Your Entry</h1>
      <p>Welcome to the Ganpati Bappa Contest 2024! Please upload your entry by filling out the form below. Make sure to confirm your upload before submitting it. Good luck!</p>
      <div className="mobile-app-section">
        <h3>Install the Yuvalink App</h3>
        <p>To stay updated and check the status of your participation, you can install the Yuvalink mobile app by clicking the link below:</p>
        <a href="/mobile-app" target="_blank" rel="noopener noreferrer">
          Download Yuvalink App
        </a>
        <p>Install the app on your mobile device and easily track the participant list and contest updates.</p>
      </div>
      <form className="upload-form" onSubmit={handleSubmit}>
      <p className="warning">Don't enter fake mobile and email ID, we remove that data.</p>
        
        <div className="form-group">
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="mobile">Mobile:</label>
          <input
            type="tel"
            id="mobile"
            ref={mobileInputRef} // Use ref for mobile input to focus
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
            required
          />
          {mobileError && <p style={{ color: 'red' }}>{mobileError}</p>}
        </div>
        <div className="form-group">
          <label htmlFor="image">Image:</label>
          <input
            type="file"
            id="image"
            onChange={handleImageChange}
          />
        </div>

        {imagePreview && (
          <div className="image-preview">
            <p>Image Preview:</p>
            <img src={imagePreview} alt="Selected" style={{ maxWidth: '100%', height: 'auto' }} />
          </div>
        )}

        {/* Conditionally render the Confirm button */}
        {!isConfirmed && (
          <button type="button" onClick={handleConfirm} disabled={!image}>
            Confirm Upload
          </button>
        )}

        <br />
        <br />

        {/* Conditionally hide the Submit button after successful submission */}
        {!isSubmitted && (
          <button type="submit" disabled={!isConfirmed}>
            Submit
          </button>
        )}
      </form>

      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <h2>Thank you for participating!</h2>
            <p>We will approve your submission shortly after validation.</p>
            <p>Once approved, you can see your participation on the following link:</p>
            <a href="https://yuvalink.com/participantsPage">View Participants</a>
          </div>
        </div>
      )}
    </div>
  );
}

export default UploadPage;
