import React, { useState, useEffect } from 'react';
import axios from 'axios';
import likeImg from '../Images/like.svg'; // Path to like image
import unlikeImg from '../Images/unlike.svg'; // Path to unlike image
import whatsaap from '../Images/whatsup.png'; // Path to unlike image
import APIURL from '../config'; // Import the global API URL
import { useNavigate } from 'react-router-dom';

function ProductCard({ image, title, price, onAddToCart, showAlert, alertMessage, id, likes }) {
  const [liked, setLiked] = useState(false);
  const [likeCount, setLikeCount] = useState(likes);
  const navigate = useNavigate();
  // Retrieve userId from localStorage
  const userId = localStorage.getItem('userId');

  useEffect(() => {
    // Optionally, fetch the initial like status here
    // Example: check if the product is liked by this user and set the `liked` state accordingly
  }, [id, userId]);

  const handleLikeToggle = async () => {
    try {
      if (!userId) {
        navigate('/login');
        return;
      }

      const response = await axios.post(`${APIURL}/likes`, { userId, productId: id }, {
        headers: { 'Authorization': 'ubpatil' }
      });
      if (response.data.message === 'Product liked') {
        setLiked(true);
        setLikeCount(prevCount => prevCount + 1);
      } else if (response.data.message === 'Like removed') {
        setLiked(false);
        setLikeCount(prevCount => prevCount - 1);
      }
    } catch (error) {
      console.error('Error toggling like:', error);
    }
  };

  return (
    <div className="col-md-4 mb-4">
      <div className="card">
        <img src={image} className="card-img-top" alt={title} />
        <div className="card-body">
          <h5 className="card-title">{title}</h5>
          <p className="card-text">{price}</p>
          <div className="button-container">
            <button className="btn btn-success" onClick={onAddToCart}>Enquire Now</button>
            <button 
              className="like-button" 
              onClick={handleLikeToggle}
            >
              {likeCount > 0 && (
                <span className="like-count">
                  {likeCount}
                </span>
              )}
              <img src={likeCount > 0 ? likeImg : unlikeImg} alt={likeCount > 0 ? 'Unlike' : 'Like'} style={{ width: 24, height: 24 }} />
            </button>
            <div className="whatsapp-button">
                <a href="https://wa.me/8261808996?text=Hello!%20I%20would%20like%20to%20get%20more%20information." target="_blank" rel="noopener noreferrer">
                    <img src={whatsaap} alt="Contact Us on WhatsApp"  />
                </a>
            </div>
          </div>
          {showAlert && (
            <div className="alert-popup show">
              {alertMessage}
            </div>
          )}
        </div> 
      </div>
    </div>
  );
}

export default ProductCard;
