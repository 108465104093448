import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './CSS/OrderDetails.css'; // Custom CSS file
import '../../Breadcrumbs.css'; // Custom CSS file
import APIURL from '../../config.js';
import ImageModal from './ImageModal'; // Import the ImageModal component
import Breadcrumbs from '../Breadcrumbs'; // Import the Breadcrumbs component

function ViewOrders() {
    const [orders, setOrders] = useState([]);
    const [orderItems, setOrderItems] = useState([]);
    const [users, setUsers] = useState([]);
    const [products, setProducts] = useState([]);
    const [filteredOrders, setFilteredOrders] = useState([]);
    const [sortOrder, setSortOrder] = useState('asc');
    const [filters, setFilters] = useState({ status: '', search: '' });
    const [modalImage, setModalImage] = useState(null); // State for modal image
    const [modalOpen, setModalOpen] = useState(false); // State for modal visibility
    const [expandedOrders, setExpandedOrders] = useState(new Set()); // State for expanded rows

    const currentUserId = localStorage.getItem('userId');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [ordersRes, orderItemsRes, usersRes, productsRes] = await Promise.all([
                    axios.get(`${APIURL}/myorders`, { headers: { 'Authorization': 'ubpatil' }, params: { userId: currentUserId } }),
                    axios.get(`${APIURL}/order-items`, { headers: { 'Authorization': 'ubpatil' } }),
                    axios.get(`${APIURL}/users`, { headers: { 'Authorization': 'ubpatil' } }),
                    axios.get(`${APIURL}/products`, { headers: { 'Authorization': 'ubpatil' } })
                ]);

                setOrders(ordersRes.data);
                setOrderItems(orderItemsRes.data);
                setUsers(usersRes.data);
                setProducts(productsRes.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [currentUserId]);

    useEffect(() => {
        const applyFilters = () => {
            let filtered = orders.map(order => {
                const items = orderItems.filter(item => item.order_id === order.order_id);
                const orderDetails = items.map(item => {
                    const product = products.find(p => p.id === item.product_id);
                    return {
                        ...item,
                        productName: product ? product.title : 'Unknown',
                        productImage: product ? product.image : 'default.jpg'
                    };
                });
                return {
                    ...order,
                    items: orderDetails
                };
            });

            if (filters.status) {
                filtered = filtered.filter(order => order.status === filters.status);
            }

            if (filters.search) {
                filtered = filtered.filter(order =>
                    order.items.some(item =>
                        item.productName.toLowerCase().includes(filters.search.toLowerCase())
                    )
                );
            }

            setFilteredOrders(filtered);
        };

        applyFilters();
    }, [orders, orderItems, products, filters]);

    const handleSort = () => {
        const sortedOrders = [...filteredOrders].sort((a, b) => {
            if (sortOrder === 'asc') {
                return new Date(a.order_date) - new Date(b.order_date);
            } else {
                return new Date(b.order_date) - new Date(a.order_date);
            }
        });
        setFilteredOrders(sortedOrders);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    };

    const openModal = (imageSrc) => {
        setModalImage(imageSrc);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
        setModalImage(null);
    };

    const toggleExpand = (orderId) => {
        setExpandedOrders(prev => {
            const newSet = new Set(prev);
            if (newSet.has(orderId)) {
                newSet.delete(orderId);
            } else {
                newSet.add(orderId);
            }
            return newSet;
        });
    };

    const cancelOrder = async (orderId) => {
        try {
            await axios.put(`${APIURL}/orders/${orderId}/cancel`, {}, { headers: { 'Authorization': 'ubpatil' } });
            // Refresh data after canceling
            const response = await axios.get(`${APIURL}/myorders`, { headers: { 'Authorization': 'ubpatil' }, params: { userId: currentUserId } });
            setOrders(response.data);
        } catch (error) {
            console.error('Error canceling order:', error);
        }
    };

    return (
        <div className="order-details-container"> 
            <div className="filtersOrder">
                <select onChange={(e) => setFilters(prev => ({ ...prev, status: e.target.value }))}>
                    <option value="">All Statuses</option>
                    <option value="Pending">Pending</option>
                    <option value="completed">Completed</option>
                    <option value="shipped">Shipped</option>
                    <option value="Cancelled">Cancelled</option>
                </select>
                <input
                    type="text"
                    placeholder="Search by product name..."
                    onChange={(e) => setFilters(prev => ({ ...prev, search: e.target.value }))}
                />
                <button onClick={handleSort}>
                    Sort by Date ({sortOrder === 'asc' ? 'Ascending' : 'Descending'})
                </button>
            </div>
            <table className="order-details-table">
                <thead>
                    <tr>
                        <th>Enquiry ID</th>
                        <th>Name</th>
                        <th>Username</th>
                        <th>Quantity</th>
                        <th>Price</th>
                        <th>Enquiry Date</th>
                        <th>User Email</th>
                        <th>User Mobile</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredOrders.map(order => (
                        <React.Fragment key={order.order_id}>
                            <tr className="expandable-row">
                                <td colSpan="10">
                                    <button
                                        className="expand-collapse-button"
                                        onClick={() => toggleExpand(order.order_id)}
                                    >
                                        <span className={`arrow ${expandedOrders.has(order.order_id) ? 'expanded' : 'collapsed'}`}></span>
                                        {expandedOrders.has(order.order_id) ? 'Collapse' : 'Expand'}
                                    </button>
                                    Enquiry ID: {order.order_id} | Status: {order.status}
                                    {order.items.length > 0 && (
                                        <div className={`order-details ${expandedOrders.has(order.order_id) ? 'visible' : 'hidden'}`}>
                                            <strong>Enquiry Details:</strong>
                                            <table className="order-details-sub-table">
                                                <tbody>
                                                    {order.items.map(item => {
                                                        const user = users.find(u => u.id === order.user_id);
                                                        return (
                                                            <tr key={item.order_item_id}>
                                                                <td>
                                                                    <div className="product-image-wrapper">
                                                                        <img
                                                                            src={`${APIURL}/Images/${item.product_id}.jpg`}
                                                                            alt={item.productName}
                                                                            className="product-image"
                                                                            onClick={() => openModal(`${APIURL}/Images/${item.product_id}.jpg`)}
                                                                        />
                                                                        <div className="product-name">
                                                                            {item.productName}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>{item.quantity}</td>
                                                                <td>{item.price}</td>
                                                                <td>{order.order_date}</td>
                                                                <td>{user?.email}</td>
                                                                <td>{user?.mobile}</td>
                                                                <td>{order.status}</td>
                                                                <td>
                                                                    {order.status === 'Pending' && (
                                                                        <button
                                                                            onClick={() => cancelOrder(order.order_id)}
                                                                            className="cancel-button"
                                                                        >
                                                                            Cancel
                                                                        </button>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    )}
                                </td>
                            </tr>
                        </React.Fragment>
                    ))}
                </tbody>
            </table>
            <ImageModal
                imageSrc={modalImage}
                isOpen={modalOpen}
                onClose={closeModal}
            />
        </div>
    );
}

export default ViewOrders;
