import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import APIURL from '../config'; // Import the global API URL
import '../LoginPage.css'; // Import the CSS file

function LoginPage() {
  const [emailOrMobile, setEmailOrMobile] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [forgotEmailOrMobile, setForgotEmailOrMobile] = useState('');
  const [loading, setLoading] = useState(false); // Loader state
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${APIURL}/login`, {
        email: emailOrMobile,
        mobile: emailOrMobile,
        password
      }, { headers: { 'Authorization': 'ubpatil' } });

      // Save token, userId, and isAdmin to local storage
      localStorage.setItem('authToken', response.data.token);
      localStorage.setItem('userId', response.data.userId);
      localStorage.setItem('isAdmin', response.data.isAdmin ? 'true' : 'false');
      localStorage.setItem('username', response.data.userName);
      
      // Force re-render of Navbar by triggering a storage event
      window.dispatchEvent(new Event('storage'));

      // Redirect to products page
      navigate('/');
    } catch (error) {
      console.error('Login error:', error);
      setMessage('Login failed. Please check your credentials.');
    }
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setLoading(true); // Show loader

    try {
      await axios.post(`${APIURL}/forgot-password`, { emailOrMobile: forgotEmailOrMobile }, { headers: { 'Authorization': 'ubpatil' } });
      setMessage('Password reset link sent to your email or mobile.');
      setForgotEmailOrMobile('');
      setShowForgotPassword(false);
    } catch (error) {
      console.error('Forgot password error:', error);
      setMessage('Error sending password reset link.');
    } finally {
      setLoading(false); // Hide loader
    }
  };

  return (
    <div className="login-container">
      <h2>Login</h2>
      {message && <p>{message}</p>}
      {showForgotPassword ? (
        <div>
          <form onSubmit={handleForgotPassword} className="forgot-password-form">
            <div className="form-group">
              <label htmlFor="forgotEmailOrMobile">Email or Mobile:</label>
              <input
                type="text"
                id="forgotEmailOrMobile"
                value={forgotEmailOrMobile}
                onChange={(e) => setForgotEmailOrMobile(e.target.value)}
                required
              />
            </div>
            <button type="submit">Send Reset Link</button>
            <button type="button" onClick={() => setShowForgotPassword(false)}>Back to Login</button>
          </form>
          {loading && <img src="/images/loader.png" alt="Loading..." className="loader" />}
        </div>
      ) : (
        <form onSubmit={handleLogin} className="login-form">
          <div className="form-group">
            <label htmlFor="emailOrMobile">Email or Mobile:</label>
            <input
              type="text"
              id="emailOrMobile"
              value={emailOrMobile}
              onChange={(e) => setEmailOrMobile(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password:</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit">Login</button>
          <p>
            <a href="#" onClick={() => setShowForgotPassword(true)}>Forgot password?</a>
          </p>
        </form>
      )}
      <p>Don't have an account? <a href="/signup">Sign up</a></p>
    </div>
  );
}

export default LoginPage;
