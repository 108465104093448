import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import API_URL from '../../config.js';
import { useTable, useSortBy, usePagination } from 'react-table';
import './CSS/ContactPanel.css'; // Import the responsive CSS

const ContactPanel = () => {
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterInput, setFilterInput] = useState('');
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  // Use useCallback to memoize the fetchContacts function
  const fetchContacts = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_URL}/contactList`, {
        params: { page: pageIndex + 1, size: pageSize, filter: filterInput },
        headers: { 
          'Authorization': 'ubpatil' 
        }
      });

      // Sort the data by ID in descending order
      const sortedData = response.data.data.sort((a, b) => b.id - a.id);

      setContacts(sortedData);
      setTotalRecords(response.data.total);
    } catch (error) {
      console.error('Error fetching contacts:', error);
    } finally {
      setLoading(false);
    }
  }, [pageIndex, pageSize, filterInput]);

  useEffect(() => {
    fetchContacts();
  }, [fetchContacts]);

  const columns = React.useMemo(
    () => [
      { Header: 'ID', accessor: 'id' },
      { Header: 'Name', accessor: 'name' },
      { Header: 'Email', accessor: 'email' },
      { Header: 'Mobile', accessor: 'mobile' },
      { Header: 'Message', accessor: 'message' },
      { Header: 'Created At', accessor: 'created_at' },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    previousPage,
    nextPage,
    setPageSize: setTablePageSize,
  } = useTable(
    { 
      columns, 
      data: contacts, 
      initialState: { pageIndex, pageSize }
    },
    useSortBy,
    usePagination
  );

  return (
    <div>
      <input
        type="text"
        value={filterInput}
        onChange={(e) => setFilterInput(e.target.value)}
        placeholder="Filter by name"
      />
      {loading ? (
        <p>Loading...</p>
      ) : (
        <table {...getTableProps()} className="table">
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())} key={column.id}>
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={row.id}>
                  {row.cells.map(cell => (
                    <td {...cell.getCellProps()} data-label={cell.column.Header} key={cell.column.id}>
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
      <div className="pagination">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </button>
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </button>
        <button onClick={() => gotoPage(pageOptions.length - 1)} disabled={!canNextPage}>
          {'>>'}
        </button>
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        <select
          value={pageSize}
          onChange={e => {
            setTablePageSize(Number(e.target.value));
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map(size => (
            <option key={size} value={size}>
              Show {size}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default ContactPanel;
