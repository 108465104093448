import React from 'react';
import { Navigate } from 'react-router-dom';

const AuthWrapper = ({ children }) => {
    const isLoggedIn = !!localStorage.getItem('authToken'); // Check if the user is logged in
    const isAdmin = localStorage.getItem('isAdmin') === 'true'; // Check if the user is an admin

    if (!isLoggedIn) {
        return <Navigate to="/login" />; // Redirect to login if not logged in
    }

    if (!isAdmin) {
        return <Navigate to="/" />; // Redirect to home if not an admin
    }

    return children; // Render the children if authenticated and authorized
};

export default AuthWrapper;
