import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer className="bg-light text-center text-lg-start">
      <div className="text-center p-3">
        &copy; 2024 Ganapati Bappa Moraya by Umesh Patil | <Link to="/contact">Contact Us</Link>
      </div>
    </footer>
  );
}

export default Footer;
