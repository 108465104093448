// AboutUs.js
import React from 'react'; 
import '../App.css';

function AboutUs() {
  return (
    <div className="about-us"> 
      <h2>About Us</h2>
      <p>We provide Ganesh Bappa Moraya Murti bookings in Badlapur area. Our mission is to promote sustainable and environmentally friendly practices during the Ganesh Chaturthi festival.</p>
      <p>Our Ganesh Murtis are made from natural materials that are safe for the environment. We offer a variety of sizes and styles to suit your needs.</p>
      <p>For more information or to place an order, please contact us.</p>
    </div>
  );
}

export default AboutUs;
