import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './CSS/AdminPage.css'; // Import CSS file for styling
import APIURL from '../../config'; // Import the global API URL

function AdminPage() {
    const [participants, setParticipants] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [sort, setSort] = useState('name');
    const [filter, setFilter] = useState('');
    const [zoomedImage, setZoomedImage] = useState(null);

    useEffect(() => {
        axios.get(`${APIURL}/adminparticipants`, {
            params: {
                page: currentPage,
                limit: 10,
                sort,
                filter
            }
        })
        .then(response => {
            console.log('API response:', response.data);
            if (response.data && Array.isArray(response.data.participants)) {
                setParticipants(response.data.participants);
                setTotalPages(Math.ceil(response.data.totalParticipants / 10));
            } else {
                console.error('Expected an array of participants but got:', response.data);
                setParticipants([]);
            }
        })
        .catch(error => console.error('Error fetching participants:', error));
    }, [currentPage, sort, filter]);

    const handleActivate = (id) => {
        axios.get(`${APIURL}/admin/activate/${id}`)
            .then(response => {
                alert(response.data.message);
                setParticipants(participants.map(p => p.id === id ? { ...p, is_active: true } : p));
            })
            .catch(error => {
                console.error('Error activating participant:', error);
                alert('Failed to activate participant.');
            });
    };

    const handleDeactivate = (id) => {
        axios.get(`${APIURL}/admin/deactivate/${id}`)
            .then(response => {
                alert(response.data.message);
                setParticipants(participants.map(p => p.id === id ? { ...p, is_active: false } : p));
            })
            .catch(error => {
                console.error('Error deactivating participant:', error);
                alert('Failed to deactivate participant.');
            });
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleSortChange = (e) => {
        setSort(e.target.value);
    };

    const handleFilterChange = (e) => {
        setFilter(e.target.value);
    };

    const handleImageClick = (image) => {
        setZoomedImage(image);
    };

    const closeZoomedImage = () => {
        setZoomedImage(null);
    };

    const generateWhatsAppLink = (participant) => {
        const message = `Hi! I need your help to support ${participant.name} in a contest. Please like their profile using this link:\n\nhttps://yuvalink.com/participantsPage?participantId=${participant.id}\n\nGanpati Bappa Moraya! Your support means a lot and could help them win the top spot! Thank you!`;
        return `https://wa.me/?text=${encodeURIComponent(message)}`;
    };

    const handleSendWhatsApp = (participant) => {
        const whatsappLink = generateWhatsAppLink(participant);
        window.open(whatsappLink, '_blank');
    };

    return (
        <div className="admin-page">
            <h1>Admin Page - Manage Participants</h1>
            <div className="controls">
                <select onChange={handleSortChange} value={sort}>
                    <option value="name">Name</option>
                    <option value="like_count">Likes</option>
                </select>
                <input
                    type="text"
                    placeholder="Search by name..."
                    value={filter}
                    onChange={handleFilterChange}
                />
            </div>
            <div className="participants-list">
                {participants.map(participant => (
                    <div key={participant.id} className="participant-card">
                        <h3>{participant.name}</h3>
                        <img 
                            src={`${APIURL}/upload/${participant.image}`} 
                            alt={participant.name} 
                            className="participant-image"
                            onClick={() => handleImageClick(`${APIURL}/upload/${participant.image}`)} 
                        />
                        <p>Likes: {participant.like_count}</p>
                        <p>Mobile: {participant.mobile}</p> {/* Display mobile number */}
                        <button
                            onClick={() => participant.is_active ? handleDeactivate(participant.id) : handleActivate(participant.id)}
                            disabled={participant.is_active === undefined} // Disable button if `is_active` is undefined
                        >
                            {participant.is_active ? 'Deactivate' : 'Activate'}
                        </button>
                        <button
                            onClick={() => handleSendWhatsApp(participant)}
                        >
                            Send WhatsApp
                        </button>
                    </div>
                ))}
            </div>
            <div className="pagination">
                <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                >
                    Previous
                </button>
                <span>Page {currentPage} of {totalPages}</span>
                <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                >
                    Next
                </button>
            </div>

            {zoomedImage && (
                <div className="modal" onClick={closeZoomedImage}>
                    <div className="modal-content" onClick={e => e.stopPropagation()}>
                        <span className="close" onClick={closeZoomedImage}>&times;</span>
                        <img src={zoomedImage} alt="Zoomed" className="zoomed-image" />
                    </div>
                </div>
            )}
        </div>
    );
}

export default AdminPage;
